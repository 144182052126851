/**
 *
 * Custom function
 *
 */ 

(function($) {

	$(document).ready(function() {

		/*if ($('.riquadro').get() != '') {
			var h = $('.riquadro').height();
			var p = $('.riquadro').offset();
			$("body").css("background-position","90% "+(h+(p.top)-200)+'px');
		}

		if ($('.riquadroUp').get() != '') {
			var h = $('.riquadroUp').height();
			var p = $('.riquadroUp').offset();
			$("body").css("background-position","90% "+(h+(p.top)-200)+'px');
		}*/
		scrollAnimations(270, true);

		if($('.show-phone').length != 0) {
			$('.show-phone').click(function(e) {
				e.preventDefault();
				$('.show-phone').hide();
				$('.hotel-phone-number').show();
				ga('send', 'event', 'Link', 'Click', 'Mostra Telefono');
			});
		}

		$('.gform_wrapper form').each(function() {
			//$(this).checkEditaForm({bgerror: '#fef1f8'});
			$(this).validate({bgerror: '#fef1f8'});
		});

		/*$('.checkNewsletter').each(function() {
			//$(this).checkEditaForm({bgerror: '#fef1f8'});
			$(this).validate({bgerror: '#fef1f8'});
		});*/

		$('#map_canvas_multi').addClass('scrolloff');
		$('#mappa').on('click', function() {
			$('#map_canvas_multi').removeClass('scrolloff');
		});
	 
		$('#mappa').mouseleave(function() {
			$('#map_canvas_multi').addClass('scrolloff');
		});

		/*
		 *	Attraverso il Google Loader carico il modulo mappa
		 */
		if (home != '1') {
			$.getScript('https://www.google.com/jsapi', function() {
				var map_params = 'language=' + lang + (google_api_key != '' ? '&key=' + google_api_key : '');
				google.load('maps', '3', {
						other_params: map_params,
						callback: function() {
								initializeGmap();
						}
				});
			});
		}

	});


	function scrollAnimations(desktopScrollTop, desktopScrollBol) {
			var header = $('#js-header'),
					menu = $('#js-fixed-menu'),
					desktopFixedMenu = $('#js-desktopFixedMenu'),
					windowWidth = $(window).width();

			if ($('body').is('.is-mobile')) {
					var headerH = $('#js-header').outerHeight(), // Height compreso di margini e bordi
							menuH = $('#js-fixed-menu').innerHeight(), // Height senza margini e bordi
							scrollValue = headerH - menuH;

					header.css('height', headerH);

			}

			$(window).scroll(function() {
					scrolltop = $(window).scrollTop();

					if ($('body').is('.is-mobile')) {
						if ( scrolltop >= scrollValue) {
								menu.addClass("scroll");
						} else {
								menu.removeClass("scroll");
						}
					} else if ( desktopScrollBol ) {
						if (scrolltop >= desktopScrollTop) {
							header.addClass("scroll");
						} else {
								header.removeClass("scroll");
						}
					}

			});

	}

	/*
	 *	Funzione per l'inizializzazione della mappa
	 */
	function initializeGmap() {
			$('*[data-location]').each(function() {
					var map = $(this),
							address = map.attr('data-location');
					map.setGMap({
							address: address
					});
			});
	} // end func initializeGmap

	$(window).load(function() {

		if ( $( window ).width() > 991 ) {

			$('.js-equalHeights-post-descr').equalHeights();
			$('.js-equalHeights-post-title').equalHeights();
			$('.js-equalHeights-amici-thumb').equalHeights();
			$('.js-equalHeights-amici-testo').equalHeights();
			$('.js-equalHeights-struttura-thumb').equalHeights();
			$('.js-equalHeights-struttura-testo').equalHeights();
			$('.js-equalHeights-post-title').equalHeights();
			$('.js-equalHeights-post-descr').equalHeights();
			$('.js-equalHeights-riga1').equalHeights();
			$('.js-equalHeights-nome-servizio').equalHeights();
			$('.js-equalHeights-buoni-motivi').equalHeights();
		}

		if (!$('body').is('.mobile')) {
			$( "select" ).change(function () {
				var newHeight = $('#col2'). outerHeight()+60;
				$('#col1').css('height', newHeight);
				var newHeight = $('#col1'). outerHeight();
			});
		}

	});

	$(window).resize(function() {

		if ( $( window ).width() > 991 ) {

			$('.js-equalHeights-post-descr').equalHeights();
			$('.js-equalHeights-post-title').equalHeights();
			$('.js-equalHeights-amici-thumb').equalHeights();
			$('.js-equalHeights-amici-testo').equalHeights();
			$('.js-equalHeights-struttura-thumb').equalHeights();
			$('.js-equalHeights-struttura-testo').equalHeights();
			$('.js-equalHeights-post-title').equalHeights();
			$('.js-equalHeights-post-descr').equalHeights();
			$('.js-equalHeights-riga1').equalHeights();
			$('.js-equalHeights-nome-servizio').equalHeights();
			$('.js-equalHeights-buoni-motivi').equalHeights();
		}

		/*if ($('.riquadro').get() != '') {
			var h = $('.riquadro').height();
			var p = $('.riquadro').offset();
			$("body").css("background-position","90% "+(h+p.top-180)+'px');
		}*/

	});

})(jQuery);

jQuery(function($){
  $(document).on('gform_post_render', function(e, form_id){
    $('#gform_ajax_frame_'+form_id).attr('src', 'about:blank');
  });
});
